@import "../../../node_modules/bootstrap/scss/bootstrap";
@import "./sections/_main.scss";
@import "./sections/_services.scss";
@import "./sections/_apply-form.scss";
@import "./sections/contacts.scss";
@import "./sections/documents.scss";
@import "./sections/about.scss";
@import "./sections/block.scss";
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
$font-family: Raleway;
$font-safari: Open Sans;
$primary: #820A23;
$black: #24262E;

body {
  -webkit-appearance: none;
  //font-family: $font-family $font-safari;
  font-style: normal;
  font-weight: normal;
  section:focus {outline:0;}
}
a {
  color: $black;
  &:hover {
    color: $primary;
    text-decoration: underline !important;
  }
}

.btn-primary {
  border-color: $primary !important;
  background: $primary !important;
}
.navbar {
  padding: 0.5rem 5rem;
  font-weight: bold;
  box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.1);
  text-transform: capitalize;
}
.main-container {
  margin-top: 77px;
}
.rounded-btn {
  border-radius: 25px;
  width: 50px;
  height: 50px;
  position: fixed;
  bottom:10px;
  right: 20px;
  .phone {
    margin-left: 3px;
    margin-top: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
  }
  &:hover {
    //background: lighten(#820A23, 4%);
    margin-bottom: 3px;
    box-shadow: 0 15px 24px rgba(190, 89, 117, 0.58);
  }
}

.hero {
  background-image: url("../images/backround_image.png");
}

@media only screen and (max-width: 600px) {
  .navbar {
    padding: 0.5rem 1rem;
    .nav {
      display: block;
    }
  }
  .rounded-btn {
    width: 60px;
    height: 60px;
    border-radius: 30px;
    .phone {
      margin-left: 3px;
      margin-top: 7px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 35px;
    }
  }
}