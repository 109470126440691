.services .content {
  .service {
    margin-top: 16px;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 30px;

    h3 {
      align-self: center;
      grid-column: 1/1;
      grid-row: 1/1;
      text-align: center;
      font-weight: lighter;
    }

    ul {
      grid-column: 2/2;
      grid-row: 1/1;

      li {
        margin-bottom: 16px;
        font-weight: lighter;
        font-size: 20px;
        list-style: none;
      }
    }

    &.invert {
      h3 { grid-column: 2/2; }
      ul { grid-column: 1/2; }
    }
  }
}

@media only screen and (max-width: 730px) {
  .services .content {
    .service {
      grid-template-columns: 1fr;
      grid-template-rows: auto auto;
      h3 { grid-column: 1/1; grid-row: 1/1; }
      ul { grid-column: 1/1; grid-row: 2/2; }
      &.invert {
        h3 { grid-column: 1/1; }
        ul { grid-column: 1/1; }
      }
    }
  }
}