.documents .content {
  display: grid;
  grid-template-columns: auto 85% auto;
  grid-template-rows: auto auto auto;
  grid-row-gap: 20px;
  .category-list {
    grid-column: 2/2;
    grid-row: 1/1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%;
    padding-bottom: 1rem;
  }
  .category-label {
    &:focus {outline:0;}
    flex-grow: 1;
    margin-right: 0.5rem;
    white-space: nowrap;
    scroll-behavior: smooth;
    text-align: center;
    text-transform: capitalize;
    font-weight: lighter;
    font-size: 1.5em;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    img {
      width: 180px;
      height: 250px;
      object-fit: cover;
    }
    &:hover {
      color: #820A23;
      cursor: pointer;
      text-decoration: underline !important;
      img {
        box-shadow: 4px 4px 15px rgba(10, 9, 9, 0.25);
      }
    }
    &.active {
      color: #820A23;
      text-decoration: underline !important;
      img {
        box-shadow: 4px 4px 15px rgba(10, 9, 9, 0.25);
      }
    }
  }
  .category-documents {
    margin-top: 2rem;
    margin-left: -2px;
    grid-column: 2/2;
    grid-row: 2/2;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto;
    row-gap: 3.5rem;
    column-gap: 1rem;
    .document {
      box-shadow: 4px 4px 15px rgba(10, 9, 9, 0.25);
      justify-self: center;
      width: 180px;
      height: 250px;
      .image {
        width: 180px;
        height: 250px;
        object-fit: cover;
      }
      .label {
        margin-top: 10px;
        text-align: center;
        font-weight: lighter;
      }
      &:hover {
        box-shadow: 4px 8px 15px rgba(10, 9, 9, 0.25);
      }
    }
  }
}

@media only screen and (max-width: 730px) {
  .documents .content .category-documents {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-row-gap: 4rem;
  }
}

@media only screen and (max-width: 600px) {
  .documents .content .category-documents {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-row-gap: 4rem;
  }
}

//.documents {
  //.swiper-pagination-bullet-active {
  //  background-color: #730E15;
  //}
  //.swiper-button-next, .swiper-button-prev {
  //  color: #730E15;
  //}
  //.swiper-wrapper {
  //  margin-bottom: 70px;
  //}
  //.swiper-container {
  //  padding: 40px 0 0 40px;
  //  .swiper-slide {
  //    display: flex;
  //    flex-direction: column;
  //    align-items: center;
  //    height: auto;
  //    .document {
  //      box-shadow: 9px 8px 15px rgba(10, 9, 9, 0.25);
  //      display: flex;
  //      flex-direction: column;
  //      align-items: center;
  //      justify-content: center;
  //      padding: 0 10px;
  //      width: 180px;
  //      height: 250px;
  //      img {
  //        width: 180px;
  //        object-fit: contain;
  //      }
  //    }
  //    .label {
  //      margin-top: 10px;
  //    }
  //  }
  //}
//}