.hero {
  width: 100%;
  height: calc(100vh - 77px);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: grid;
  grid-template-columns:auto 62% auto;
  grid-template-rows: auto auto 144px auto;
  column-gap: 10px;
  row-gap: 15px;
  h2 {
   grid-column: 2/2;
    grid-row: 2/2;
    text-align: center;
    color: white;
  }
  .apply-form {
    grid-column: 2/2; grid-row: 3/3;
    text-align: center;
    color: white;
  }
}

@media only screen and (max-width: 730px) {
  .hero {
    grid-template-columns: auto 90% auto;
    grid-template-rows: auto auto 190px auto;
  }
}