.about .content {
  display: grid;
  grid-template-columns: auto 85% auto;
  grid-template-rows: auto auto auto 144px;
  grid-row-gap: 20px;
  margin-bottom: 40px;
  h3, h5 {
    grid-column: 2/2;
    grid-row: 1/1;
    font-weight: lighter;
    text-align: center;
    a { border-bottom: 1px solid #730E15; padding-bottom:2px; color: #730E15}
  }

  h5 { grid-column: 2/2; grid-row: 3/3; line-height: 2.5em; text-align: center;}

  .apply-form {
    grid-column: 2/2; grid-row: 4/4;
  }
}

@media only screen and (max-width: 730px) {
  .contacts .content {
    grid-template-columns: auto 100% auto;
    grid-template-rows: auto auto auto 190px;
  }
}