.block {
  display: grid;
  grid-template-columns: auto 85% auto;
  grid-template-rows: 40px auto auto;
  grid-row-gap: 50px;
  header {
    grid-column: 2/2;
    grid-row: 2/2;
    text-transform: uppercase;
    text-align: center;
    font-size: 2em;
    font-weight: lighter;
  }
  .content {
    grid-column: 2/2;
    grid-row: 3/3;
  }
}