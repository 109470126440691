.apply-form {
  background-color: white;
  box-shadow: 9px 8px 15px rgba(10, 9, 9, 0.25);
  form {
    padding: 40px;
    display: flex;
    height: 100%;
    flex-direction: column;
    .wrapper {
      display: flex;
      flex-direction: row;
      .phone {
        border: none;
        border-bottom: 1px solid #24262E;
        background: #FFFFFF;
        flex-basis: 0;
        flex-grow: 1;
        position: relative;

        input[type=tel] {
          background: none;
          border: none;
          box-sizing: border-box;
          display: none;
          font-size: 16px;
          font-weight: normal;
          line-height: 24px;
          padding: 15px;
          width: 100%;

          &:focus {
            outline: none;
          }

          &::placeholder {
            color: #24262E;
          }
        }

        .placeholder {
          text-align: left;
          color: #24262E;
          font-size: 16px;
          font-weight: lighter;
          line-height: 24px;
          padding: 15px;
        }

        &.focused {
          input[type=tel] {
            display: block;
          }

          .placeholder {
            display: none;
          }
        }
      }
      input[type=submit] {
        -webkit-appearance: none;
        border: none;
        border-radius: 4px;
        //box-shadow: 0 16px 24px rgba(200, 66, 103, 0.56);
        cursor: pointer;
        //font-family: $corpFont;
        font-style: normal;
        text-transform: uppercase;
        font-weight: 300;
        font-size: 16px;
        line-height: 19px;
        background: #820A23;
        color: white;
        margin-left: 32px;
        height: 56px;
        flex-basis: 0;
        flex-grow: 1;
        transition: all 0.1s linear;

        &:hover {
          background: lighten(#820A23, 4%);
          box-shadow: 0 20px 24px rgba(190, 89, 117, 0.58);
        }
        &:disabled {
          background: darken(#820A23, 10%);
          box-shadow: 0 4px 12px rgba(200, 66, 103, 0.2);
          content: "asdasd"
        }
      }
    }
    .error-message {
      text-align: left;
      border-left: 3px solid #730E15;
      color: #730E15;
      display: none;
      font-size: 16px;
      margin: -24px 0 16px;
      padding: 0 8px;
    }
  }
  //.confirmation {
  //  color: #02A3DF;
  //  font-size: 14px;
  //  font-style: normal;
  //  line-height: 20px;
  //  padding: 16px 0 0;
  //}

  .success {
    color: white;
    text-align: center;
    background: #730E15;
    display: none;
    height: 100%;
    //padding: 24px 32px;
    padding:25px 40px;
    h3 {
      margin: 0 0 4px;
    }
  }

  &.submitted {
    form { display: none; }
    //.confirmation { display: none; }
    .success { display: block; }
  }
}

@media only screen and (max-width: 730px) {
  .apply-form {
    form {
      .error-message {
       margin:0
      }
      .wrapper {
        display: block;
        height: auto;
        .phone {
          .placeholder {
            font-size: 18px;
          }
        }
        input[type=submit] {
          margin: 8px 0 0;
          font-size: 16px;
          width: 100%;
        }
      }
      //.confirmation {
      //  color: #aaa;
      //  font-size: 12px;
      //  padding: 8px 0 0;
      //}
    }
  }
}